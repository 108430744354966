<template>
  <div class="exam-passed">
    <div class="passed-wrapper">
      <div class="zlxc-container" style="overflow: visible">
          <div class="exam-outcome">
              <div class="outcome-fraction">
                  <p class="fraction-label">分数</p>
                  <p class="fraction-num">{{score}}</p>
              </div>
              <div class="outcome-fraction">
                  <p class="fraction-label">用时</p>
                  <p class="fraction-num">{{useTime}}</p>
              </div>
          </div>
          <div class="exam-question">
              <div class="question-fraction">
                  <p class="fraction-label">答对</p>
                  <p class="fraction-num">{{proper}}题</p>
              </div>
              <div class="question-fraction">
                  <p class="fraction-label">答错</p>
                  <p class="fraction-num">{{mistake}}题</p>
              </div>
              <div class="question-fraction">
                  <p class="fraction-label">漏答</p>
                  <p class="fraction-num">{{forgetNum}}题</p>
              </div>
          </div>
          <div class="exam-backtrack" @click="tabBacktrack">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExamPassed",
  data() {
    return {
      proper: 0,
      mistake: 0,
      total: 0,
      score: 0,
      maxtime: 0,
      useTime: "45:00",
    };
  },
  computed: {
    forgetNum: function () {
      return this.total - this.proper - this.mistake
    }
  },
  created() {
    this.proper = this.$route.query.proper
    this.mistake = this.$route.query.mistake
    this.total = this.$route.query.total
    this.score = this.$route.query.score
    this.maxtime = this.$route.query.maxtime
    this.timeCountDown()
  },
  methods: {
    // 计算用时多久
    timeCountDown() {
      let _useTime = 45 * 60 - this.maxtime
      let minutes = Math.floor(_useTime / 60);
      let seconds = Math.floor(_useTime % 60);
      // "距离结束还有" + minutes + "分" + seconds + "秒"
      if (seconds < 10) seconds += "0"
      this.useTime = minutes + ":" + seconds;
    },
    tabBacktrack() {
      this.$router.push({
        name:'Theoretical',
        query: {}
      })
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
